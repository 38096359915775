const initializePendo = ({ account, user, role }) => {
    if (window.pendo) {
        window.pendo.initialize({
            visitor: {
                id: user.email,
                userID: user.userID,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                timeZone: user.timeZone,
                isAdmin: user.isAdmin,
                role: role.name,
                roleID: role.roleID
            },
            account: {
                id: `${account.subdomain}_${account.accountID}_${process.env.APP_ENV}`,
                accountID: account.accountID,
                accountStatusID: account.accountStatusID,
                directory: account.directory,
                subdomain: account.subdomain,
                email: account.email,
                name: account.name,
                environment: process.env.APP_ENV,
                timeZone: account.timeZone,
                stateID: account.stateID,
                countryID: account.countryID,
                managementFeeMode: account.managementFeeMode,
                showManagerLedger: account.showManagerLedger == 1
            }
        });
    }
};

export default initializePendo;
